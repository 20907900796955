import React from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from "../../services/PrivateRoute";
// Get user info from context
import { getCurrentUser } from "../../services/user";

import MakeBooking from "./MakeBooking";
import ManageStudents from "./ManageStudents";
import ManageWordBundles from "./ManageWordBundles";
import ModuleDetails from "./ModuleDetails";
import ModuleVocabulary from "./ModuleVocabulary";
import NewDrill from "./NewDrill";
import TeacherReviews from "./TeacherReviews";
import StoryDetails from "./StoryDetails";

/**
 * Controls routes and permissions for /manage pages
 * 
 * @returns Routing switch that handles all /manage requests
 */
const Manage = () => {
  // Info for logged-in user
  const userInfo = getCurrentUser();

  // Make Booking should only be available to admins
  const MakeBookingProtected = () => (
    userInfo.isAdmin
      ? <MakeBooking />
      : <Redirect to="/" />
  );
  
  // ManageStudents should only be available to admins / teachers who can manage students
  const ManageStudentsProtected = () => (
    userInfo.isAdmin || userInfo.canManageStudents
      ? <ManageStudents />
      : <Redirect to="/" />
  );

  // NewDrill should only be available to admins
  const NewDrillProtected = () => (
    userInfo.isAdmin
      ? <NewDrill />
      : <Redirect to="/" />
  );

  const ManageWordBundlesProtected = () => (
    userInfo.isAdmin || userInfo.canManageWordBundles
      ? <ManageWordBundles />
      : <Redirect to="/" />
  );

  return (
    <Switch>
      <PrivateRoute
        path="/manage/makeBooking"
        component={MakeBookingProtected}
      />
      <PrivateRoute
        path="/manage/modules/vocabulary"
        component={ModuleVocabulary}
      />
      <PrivateRoute
        path="/manage/modules"
        component={ModuleDetails}
      />
      <PrivateRoute
        path="/manage/stories"
        component={StoryDetails}
      />
      <PrivateRoute
        path="/manage/students"
        component={ManageStudentsProtected}
      />
      <PrivateRoute
        path="/manage/newdrill"
        component={NewDrillProtected}
      />
      <PrivateRoute
        path="/manage/wordbundles"
        component={ ManageWordBundlesProtected }
      />
      <PrivateRoute
        path="/manage/teacherReviews"
        component={ TeacherReviews }
      />
    </Switch>
  );
};

export default Manage;
