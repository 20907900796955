// Name too silly? This is the thing that provides the call context
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import Daily from "@daily-co/daily-js";
import nosleep from "nosleep.js";

import {
  asyncGetDailyToken,
  /* nominateCandidatesForSpeaker, */
} from "../../../services/datastore";
import { talkka } from "../../../services/logging";

import { useAudienceTurn } from "./useAudienceTurn";

import {
  startSilentAudioElement, 
  stopSilentAudioElement,
} from "./audioHelpers";
import {
  getParticipantName,
  getParticipantTreatmentType,
} from "./participantHelpers";

// Create a context to use for all Daily call information
const CallContext = createContext();

export const Holodeck = ({ children }) => {

  /*
   * STATE - INTERNAL
   */

  // Call object used for interacting with Daily call & room
  const [DailyCallObject, setDailyCallObject] = useState(null);

  // Wake Lock object to keep screen from sleeping
  const [ wakeLock, setWakeLock ] = useState(null);

  /*
   * STATE - EXPORTED
   * These are "exported" in context value
   */

  // Flag indicating if local participant has muted self
  const [ isMuted, setIsMuted ] = useState(true);

  // Participant objects for all participants in room, regardless of treatment type
  const [ allParticipants, setAllParticipants ] = useState(null);

  // Array of studentIds of Observers currently in room
  const [ observerStudentIds, setObserverStudentIds ] = useState([]);

  // Array of Participant Objects of Candidates (CANDIDATE-SPEAKER) in the room
  const [ candidateParticipants, setCandidateParticipants ] = useState([]);

  // Participant objects for Speakers who are currently in the room
  const [ presentSpeakerParticipants, setPresentSpeakerParticipants ] = useState(null);

  // StudentIds of participants (students) who are allowed to broadcast right now
  const [ broadcastingStudentIds, setBroadcastingStudentIds ] = useState([]);

  // StudentIds of participants (students) who have muted themselves
  const [ mutedSpeakerStudentIds, setMutedSpeakerStudentIds ] = useState([]);

  // Slide index lives here because it's needed in all page components, and is sent to Daily participants based on room events
  const [ slideIndex, setSlideIndex ] = useState(0);

  // Participant object of speaker who is "on stage with microphone in hand" right now, decorated with Daily sessionId
  const [ currentSpeakerParticipant, setCurrentSpeakerParticipant ] = useState(null);

  // Participant object of participant whose mic is currently loudest
  const [ loudestParticipant, setLoudestParticipant ] = useState( null );

  // In conversation mode, all Speakers are broadcast-enabled
  // Not in conversation mode, only current student can speak
  const [ conversationMode, setConversationMode ] = useState(true);

  // In All Speak mode, all participants are broadcast-enabled
  // Before the DI joins, the Drill is in All Speak by default
  const [ allSpeakMode, setAllSpeakMode ] = useState(true);

  // Check if Daily room start time (nbf) has passed
  const [ roomWindowStarted, setRoomWindowStarted ] = useState(false);

  // Check if Daily room end time (exp) has passed
  const [ roomWindowEnded, setRoomWindowEnded ] = useState(false);

  // Confirm that local user is connected to Daily signaling server
  const [ isConnectedToDaily, setIsConnectedToDaily ] = useState(false);

  // Remember lessonId - used in useAudienceTurn hook
  const [ lessonId, setHolodeckLessonId ] = useState(null);

  // Track moduleId when module in Teach.js state changes
  const [ moduleId, setHolodeckModuleId ] = useState(null);

  // Boolean indicating that local user was ejected from Daily room
  const [ userWasEjected, setUserWasEjected ] = useState(false);

  // Boolean indicating if keyboard (or gesture) shortcuts should be enabled at this point in time
  const [ allowShortcuts, setAllowShortcuts ] = useState(false);

  // track total number of beepboops given during a drill 
  const [totalBeepboops, setTotalBeepboops] = useState(0);

  /*
   * USE EFFECT
   */

  // On load, create wakeLock. On unmount, disable it!
  useEffect(() => {
    
    // Create wake lock object
    const newWakeLock = new nosleep({ title: "Beepboop Teach App" });
    
    // Save it in state
    setWakeLock(newWakeLock);
    
    // On component unmount, clear the wake lock
    return function allowSleep() {
      newWakeLock.disable();
      // And stop silent audio track
      stopSilentAudioElement();
    };
  }, []);
  
  // On unmount, destroy the DailyCallObject
  useEffect(() => {
    // This useEffect will fire twice: once when page loads (DCO = null), and once when DCO is updated by asyncJoinCall
    // We only want to return destroyCall the second time, when DCO is truthy, so that it only fires on unmount
    if ( DailyCallObject ) {
      return function destroyCallObject() {
        DailyCallObject.destroy();
      };
    }
    
  }, [ DailyCallObject ]);

  // Attach handlers to DailyCallObject
  // WARNING: In order to accurately access a global variable (such as state) in Daily event handlers, you MUST add that variable to the dependency array of this useEffect. Yes, it's annoying. No, we can't change it until/unless Daily changes how they handle handlers. So just do it.
  useEffect(() => {
    // If the call object is null (on initial load), don't do anything!
    if (!DailyCallObject) {
      return;
    }

    // Register connection to Daily signaling server
    DailyCallObject.on("joined-meeting", handleJoinedMeeting);

    // Add listeners to update allParticipants in state when any participant changes occur
    DailyCallObject.on("participant-joined", handleParticipantJoined);
    DailyCallObject.on("participant-left", handleParticipantLeft);
    DailyCallObject.on("participant-updated", handleParticipantUpdated);

    // When the loudest mic in the room changes, update state
    DailyCallObject.on("active-speaker-change", handleLoudestParticipantChange);

    // Listen for Daily errors
    DailyCallObject.on("error", handleError);
    DailyCallObject.on("camera-error", handleError);
    DailyCallObject.on("nonfatal-error", handleError);

    // Cleanup function to remove all listeners
    return function removeDailyListeners() {
      DailyCallObject.off("joined-meeting", handleJoinedMeeting);
      DailyCallObject.off("participant-joined", handleParticipantJoined);
      DailyCallObject.off("participant-left", handleParticipantLeft);
      DailyCallObject.off("participant-updated", handleParticipantUpdated);
      DailyCallObject.off(
        "active-speaker-change",
        handleLoudestParticipantChange,
      );
      DailyCallObject.off("error", handleError);
      DailyCallObject.off("camera-error", handleError);
      DailyCallObject.off("nonfatal-error", handleError);
    };

  }, [
    allSpeakMode,
    broadcastingStudentIds,
    conversationMode,
    currentSpeakerParticipant,
    DailyCallObject,
    moduleId,
    slideIndex,
  ]);

  // When isMuted changes, make corresponding adjustment in Daily
  useEffect(() => {
    // Make sure the call object is instantiated before attempting update
    if (DailyCallObject) {
      // If isMuted is true, local audio should be false
      DailyCallObject.setLocalAudio(!isMuted);
    }
  }, [ isMuted ]);

  /**
   * When allParticipants list changes, ensure that appropriate state is
   * updated.
   */
  useEffect(() => {
    // If allParticipants doesn't exist, don't do things with it!
    if (!allParticipants) {
      return;
    }

    // Set presentSpeakerParticipants in state
    setPresentSpeakerParticipants(
      allParticipants
        ?.filter((p) => getParticipantTreatmentType(p) === "SPEAKER"),
    );

    // Update list of present Candidate participant objects
    setCandidateParticipants(
      allParticipants
        ?.filter(p => getParticipantTreatmentType(p) === "CANDIDATE-SPEAKER"),
    );

    // Update list of present Observer studentIds
    setObserverStudentIds(
      allParticipants
        // filtering by observer to rule out speakers and the instructor
        ?.filter(p => getParticipantTreatmentType(p) === "OBSERVER")
        ?.map(o => o.user_id),
    );

  }, [ allParticipants ]);

  // When an allParticipants change triggers a presentSpeakerParticipants change, update associated state and lesson status info
  useEffect(() => {
    // Update muted Speaker list by extracting studentId for Speakers with audio: false
    const newMutedSpeakers = presentSpeakerParticipants
      ?.filter(p => !p.audio)
      ?.map(p => p.user_id);
    setMutedSpeakerStudentIds( newMutedSpeakers );

  }, [ presentSpeakerParticipants ]);

  // Send slideIndex to all Daily participants when it changes
  useEffect(() => {
    sendMessage({ slideIndex });
  }, [ slideIndex ]);

  // Send broadcastingStudentIds to all Daily participants when it changes
  useEffect(() => {
    sendMessage({ broadcastingStudentIds });
  }, [ broadcastingStudentIds ]);

  // In all cases, when we change between Drill and Conversation modes, currentSpeakerParticipant should be null
  // This will trigger the useEffect below (with conversationMode and currentSpeakerParticipant dependencies), which will accurately change broadcast status and send students messages that reflect current conversationMode
  useEffect(() => {
    setCurrentSpeakerParticipant(null);
  }, [ conversationMode ]);

  // Manage broadcast permissions for Conversation Mode, All Speak mode, and current student (Drill Mode)
  useEffect(() => {

    // If in All Speak mode, everyone can speak and we have no current speaker
    if ( conversationMode && allSpeakMode ) {
      // In conversation mode, we designate currentSpeaker as "all" to students
      sendMessage({ currentSpeakerId: "all" });
      // Enable all participants to speak
      enableBroadcastForAllParticipants();
    }

    // If we're in conversation mode, current student has no bearing
    else if ( conversationMode ) {
      // In conversation mode, we designate currentSpeaker as "all" to students
      sendMessage({ currentSpeakerId: "speakers" });
      // Enable conversation between Speakers (not Observers or Candidates)
      enableBroadcastForAllSpeakers();
    }

    // If we're not in conversation mode and there's no current student, nobody should be able to broadcast
    else if ( !currentSpeakerParticipant ) {
      // Clear current student for students
      sendMessage({ currentSpeakerId: null });
      // No students are broadcasting
      disableBroadcastForAll();
    }

    // If we're not in conversation mode and we have a current student, allow that student to broadcast
    else {
      // Notify students of who currentSpeaker is
      sendMessage({ currentSpeakerId: currentSpeakerParticipant.user_id });
      // Only current student should be broadcasting
      enableBroadcastForStudent( currentSpeakerParticipant.user_id, true );
    }
    
  }, [
    allSpeakMode,
    conversationMode,
    currentSpeakerParticipant,
  ]);

  /*
   * HELPERS - INTERNAL
   */

  /**
   * Enables broadcast for all Speakers in the room, or for all Speakers in the
   * passed array of Registrant objects
   *
   * @param { Array } lessonSpeakers [optional] - Array of Registrant
   * objects for Speakers to enable
   */
  const enableBroadcastForAllSpeakers = ( lessonSpeakers = false ) => {
    // If given a list of speakers to enable, use that. If not, enable all Speakers
    const speakerIds = lessonSpeakers
      ? lessonSpeakers.map(speaker => speaker.studentId)
      : presentSpeakerParticipants?.map(s => s.user_id);

    // Set in state
    setBroadcastingStudentIds(speakerIds);
  };
  
  // Handle Daily errors
  const handleError = (error) => {
    // If error is because room has expired, update state to reflect that
    if (
      error.errorMsg === "Meeting has ended"
      || error.error?.type === "exp-room"
    ) {
      setRoomWindowEnded(true);
    }
    // Default case: log the error in logEvents
    else {
      // Handles all errors, camera-errors, and nonfatal-errors that aren't room expiration (meeting end)
      talkka.error(error);
    }
  };

  // On meeting join, register connection to Daily and start silent audio track
  const handleJoinedMeeting = () => {
    // Register connection to Daily signaling server
    setIsConnectedToDaily(true);

    // Start silent audio - should hopefully minimize audio issues on iOS
    startSilentAudioElement();
  };

  // Fires on participant-joined event from Daily
  const handleParticipantJoined = ( event ) => {

    // Send accurate Current Speaker info to new joiner
    const currentSpeakerIdString =
      allSpeakMode
        ? "all"
        : conversationMode
          ? "speakers"
          : currentSpeakerParticipant?.user_id;

    // Use helper to send updated room info to joining participant
    sendMessage({
      broadcastingStudentIds,
      currentSpeakerId: currentSpeakerIdString ? currentSpeakerIdString : null,
      moduleId,
      slideIndex,
    }, event.participant.session_id );

    // Update allParticipants array
    updateAllParticipants();

    // If joiner is not local (shouldn't be), has same user_id, and joined after this user then kick this user out
    if (
      event.participant.user_id === DailyCallObject?.participants().local?.user_id
      && !event.participant.local
      && event.participant.joined_at.getTime() > DailyCallObject?.participants().local?.joined_at.getTime()
    ) {
      // Set ejection flag to allow leave without confirmation
      setUserWasEjected(true);

      // Leave the call
      DailyCallObject.leave();

      // Go to homepage WITHOUT a manual alert
      return history.push("/");
    }

    // If the joiner is a Speaker, update broadcastingStudentIds
    // Don't need to update for non-Speakers because SA enables broadcast for all non-Speakers in All Speak mode without relying on broadcastingStudentIds
    if ( getParticipantTreatmentType(event.participant) === "SPEAKER" ) {
      // If in convo mode, enable broadcast for all Speakers
      // Do it in a state setter instead of enableBroadcastForAll in order to access current value of conversationMode
      setBroadcastingStudentIds((prev) => {
        return conversationMode
          // If in convo mode, add to broadcast list without duplicating
          ? [...new Set([event.participant.user_id].concat(prev ? prev : []))]
          // If in Drill mode, don't update list
          : prev;
      });
    }
  };

  /**
   * When a new student becomes loudest, update Holodeck'a loudestParticipant.
   * NOTE: We use loudestParticipant, but Daily uses activeSpeaker. We prefer
   * participant because Speaker has special meaning in our app, and the object
   * we're dealing here is a Participant object from the DailyJS package
   *
   * @param { Event } event emitted by Daily
   */
  const handleLoudestParticipantChange = ( event ) => {
    // Extract new active speaker sessionId from Daily's activeSpeaker
    const newLoudestParticipantSessionId = event?.activeSpeaker?.peerId;

    // Call .participants() once and remember the result - we use it in a few places in this function
    const DailyParticipants = DailyCallObject.participants();

    // Find the participant object for the loudest participant in Daily's participants object (it's sessionId-keyed, but the key for the local student is just 'local')
    const newLoudestParticipant =
      DailyParticipants.local.session_id === newLoudestParticipantSessionId
        ? DailyParticipants.local
        : DailyParticipants[ newLoudestParticipantSessionId ];

    // Set new active participant in state
    setLoudestParticipant( newLoudestParticipant );
  };

  // Fires on participant-left event from Daily
  const handleParticipantLeft = ( event ) => {
    // Update allParticipants array
    updateAllParticipants();

    // If leaver was current speaker, clear current speaker!
    // Logic is embedded in setter function to ensure we have access to latest version of state
    setCurrentSpeakerParticipant((prev) => (
      prev?.user_id === event.participant.user_id
        ? null
        : prev
    ));

    // If leaver was in broadcastingStudentIds, remove them!
    setBroadcastingStudentIds((prev) => {
      // Check if leaving Speaker is still in the room - is so, indicates leaver was a duplicate participant who got booted
      const leaverIsDuplicate = allParticipants?.find(p => p.user_id === event.participant.user_id);
    
      // If leaver is a duplicate Speaker, don't change broadcastingStudentIds
      return leaverIsDuplicate
        ? prev
        : prev?.filter(id => id !== event.participant.user_id);
    });
  };

  // Fires on participant-updated event from Daily
  const handleParticipantUpdated = () => {
    // Just need to update the participants array
    updateAllParticipants();
  };

  // Keep allParticipants up to date, and prevent students joining from two devices
  const updateAllParticipants = () => {
    // .participants() gives an id-keyed (Daily session id) object of participants, but we want an array
    const DailyParticipants = Object.values(DailyCallObject?.participants() || []);

    // Daily has already updated the participant, so we just need to update our local state with the updated list from Daily
    setAllParticipants( DailyParticipants );
  };

  /*
   * HELPERS - EXPORTED
   * These are "exported" in context value for use in components
   */

  /**
   * Join the Daily call happening in the given room, as the given user
   * 
   * @param { Object } roomInfo - Object with all Daily room info (fetched from
   * Firestore)
   * @param { Object } userInfo - Object with user info from context
   * @returns 
   */
  const asyncJoinCall = async ({ roomInfo, userInfo }) => {
    // If we don't have room and user info, then we can't join the room!
    if (!roomInfo || !userInfo) {
      return;
    }

    // The rest of this function assumes that we don't have a Daily Call Object
    // So if we do, leave it so we don't get errors
    if (DailyCallObject) {
      DailyCallObject.leave();
    }

    // Create an empty Daily call object, with audio enabled but video disabled
    const newCallObject = Daily.createCallObject({
      audioSource: true,
      videoSource: false,
    });

    // Remember call object in state
    setDailyCallObject(newCallObject);
  
    // Turn on audio before joining call
    newCallObject.setLocalAudio(true);
  
    // Generate a token identifying the teacher as the room owner
    // Also generates user_id with leading "instructor-"
    const fetchedToken = await asyncGetDailyToken(roomInfo.name);
  
    // Join the Daily room for this lesson
    await newCallObject.join({
      url: roomInfo.url,
      token: fetchedToken,
    });
  };

  /**
   * Change a student's treatmentType in this Drill.
   *
   * This function just sends the message to the student's app that a change is
   * needed. Actually making the change and stamping the studentLesson with the
   * new type is handled in the Speak App.
   *
   * @param { DailyParticipant } participant - Full Daily participant object
   * for student to change treatment type of
   * @param { String } newLessonTreatment - Treatment type to change student
   * to. Must be a valid treatmentType enum
   * @returns { Boolean } true on success, false on failure
   */
  const changeLessonTreatment = ({
    participant,
    newLessonTreatment,
  }) => {

    // If no participant, likely case is they left while BenchModal was open. Silently do nothing
    if ( !participant ) {
      return;
    }

    // Check that we have a valid newLessonTreatment
    if (
      !newLessonTreatment
      // Only treatment type that we currently change to is OBSERVER. If/when adding treatment types that can be changed, update the array here
      || ![/* "SPEAKER", "CANDIDATE-SPEAKER", */ "OBSERVER"].includes(newLessonTreatment)
    ) {
      talkka.error("Failed to change student treatment type");
      return false;
    }

    // If we have what we need, send the message to the student to change their treatment type!
    sendMessage(
      { treatmentType: newLessonTreatment },
      participant.session_id,
    );

    // Once message is sent, return true
    return true;
  };

  /**
   * Check if current time is between Daily room open and expiration times.
   * Room opens 5 min before lesson start, closes 5 min after lesson end.
   * 
   * @param { Object } roomInfo - from Daily
   */
  const checkRoomAvailability = (roomInfo) => {
    // Get Seconds in unix epoch for this instant
    const nowSeconds = Math.floor(new Date().getTime() / 1000);

    // If we're after room open, update state
    if (nowSeconds >= roomInfo.config.nbf) {
      setRoomWindowStarted(true);
    }

    // If we're after room close, update state
    if (nowSeconds > roomInfo.config.exp) {
      setRoomWindowEnded(true);
    }
  };

  /**
   * Disable broadcast for all students in class
   */
  const disableBroadcastForAll = () => {
    // Clear out all broadcasters from state
    setBroadcastingStudentIds([]);
  };

  /**
   * Disable broadcast for one student
   * 
   * @param { String } studentId - studentId of student to disable
   */
  const disableBroadcastForStudent = ( studentId ) => {
    // Filter out the target studentId, leaving all others
    const updatedBroadcastList = broadcastingStudentIds.filter((broadcasterStudentId) => broadcasterStudentId !== studentId);

    // Set result in state
    setBroadcastingStudentIds(updatedBroadcastList);
  };

  /**
   * Enables broadcast for all students in the room, regardless of treatment
   * type
   */
  const enableBroadcastForAllParticipants = () => {

    // Grab studentIds for all participants in the room, except DI
    const allParticipantIds = allParticipants
      ?.map(p => p.user_id)
      .filter(id => !id.startsWith("instructor-"));

    // Set in state
    setBroadcastingStudentIds( allParticipantIds );
  };

  /**
   * Enable broadcast for one student
   * 
   * @param { String } studentId - studentId of student to enable
   * @param { Boolean } onStage [optional] - True if student should be only 
   * broadcasting student, false if student should just be added to existing
   * broadcast list
   */
  const enableBroadcastForStudent = ( studentId, onStage = false ) => {
    // Add student to broadcast list, or if "on stage with microphone in hand", make only broadcaster
    const updatedBroadcastList = onStage
      ? [ studentId ]
      : broadcastingStudentIds?.concat([ studentId ]);

    // Ensure that we haven't double-added any participants
    const uniqueUpdatedBroadcastList = [...new Set(updatedBroadcastList)];

    // Set result in state
    setBroadcastingStudentIds(uniqueUpdatedBroadcastList);
  };

  /**
   * Unmute teacher when "Let's Go!" is clicked
   */
  const makeFirstContact = () => {
    // Enable Wake Lock via nosleep package
    wakeLock.enable();

    // Unmute on join
    setIsMuted(false);
  };

  /**
   * Promote a present student to current speaker, when not in conversation
   * mode. Pass nothing or a falsy value to clear current student (no students
   * can broadcast).
   * 
   * @param { Object } student - Participant object for student nominated to be
   * current
   * @returns undefined
   */
  const nominateStudent = ( student ) => {

    // If in conversation mode, don't nominate!
    if ( conversationMode ) {
      return;
    }

    // If no student passed in, just clear current student
    if ( !student ) {
      setCurrentSpeakerParticipant(null);
      return;
    }

    // Set currentSpeaker in state, full firestore object and Daily session_id
    setCurrentSpeakerParticipant( student );
  };

  /**
   * Send any arbitrary mesesage object to all participants in Daily room
   * 
   * @param { Object } message - to send. Must be a valid JS object!
   * @param { String } to - the session id of the user to receive the message.
   * Default is "*", which sends to all participants
   */
  const sendMessage = ( message, to = "*" ) => {
    if (DailyCallObject) {
      DailyCallObject.sendAppMessage(message, to);
    }
  };

  /**
   * Toggle All Speak mode
   */
  const toggleAllSpeakMode = () => {
    setAllSpeakMode((prev) => !prev);
  };

  /**
   * Toggle mute / unmute for local participant
   */
  const toggleIsMuted = () => {
    setIsMuted((prev) => !prev);
  };

  // TODO: If/when we reintroduce Fill Drill behavior, refactor to account for change from candidateStudentIds to candidateParticipants
  // const fillDrill = async ( lessonId ) => {
  //   // Use candidateStudentIds to get a prioritized list for promotion
  //   const prioritizedCandidateIds = await nominateCandidatesForSpeaker( candidateStudentIds, lessonId, presentSpeakerParticipants.length );

  //   // Calculate how many Candidates we need to promote to get to 4 Speakers (or as many as possible, if we can't get to 4)
  //   let maxToPromote = 4 - presentSpeakerParticipants.length;

  //   // Loop as many times as Candidates we want to promote, stopping if we get beyond the number of Candidates in prioritizedCandidateIds
  //   for (let i = 0; i < maxToPromote && i < prioritizedCandidateIds.length; i++) {
  //     // Grab the id from the list
  //     const idToPromote = prioritizedCandidateIds[i];

  //     // Check if we have a prioritized Candidate to promote
  //     if ( !idToPromote ) {
  //       // If we don't have this one, we won't have any subsequent ones, so stop doing things!
  //       break;
  //     }

  //     // Find the Participant object for that Candidate
  //     const candidateParticipant = allParticipants.find(p => p.user_id === idToPromote);

  //     // If we don't find a participant object for this Candidate...
  //     if ( !candidateParticipant ) {

  //       // Add 1 to maxToPromote, so we can promote somebody else
  //       maxToPromote++;

  //       // Don't try to promote somebody we can't find
  //       continue;
  //     }

  //     // Send a message to that Candidate that he/she is promoted
  //     sendMessage({ promoteToSpeaker: true }, candidateParticipant.session_id);

  //     // If we're in conversation mode, add the promoted student to broadcastingStudentIds
  //     if ( conversationMode ) {
  //       setBroadcastingStudentIds((prev) => prev.concat([ idToPromote ]));
  //     }
  //   }
  // };

  // Given a participant id, promote to Speaker
  const promoteStudent = ( participantId ) => {
    const participantToPromote = allParticipants.find(p => p.user_id === participantId);

    // If we don't find a participant object for this Candidate...
    if ( !participantToPromote ) {
      // Don't try to promote somebody we can't find
      return false;
    }

    // Send a message to that student that they're being promoted
    sendMessage({ promoteToSpeaker: true }, participantToPromote.session_id);

    // If we're in conversation mode, add the promoted student to broadcastingStudentIds
    if ( conversationMode ) {
      setBroadcastingStudentIds((prev) => {
        return [...new Set([participantId].concat(prev ? prev : []))];
      });
    }
    
    return false;
  };

  /*
   * HOOKS
   */

  const history = useHistory();

  // Manage Audience Turn
  const AudienceTurn = useAudienceTurn({
    candidateParticipants,
    changeLessonTreatment,
    currentSpeakerParticipant,
    lessonId,
    nominateStudent,
  });

  /*
   * RENDER
   */

  return (
    <CallContext.Provider
      value={{
        asyncJoinCall,
        changeLessonTreatment,
        checkRoomAvailability,
        disableBroadcastForAll,
        disableBroadcastForStudent,
        enableBroadcastForAllParticipants,
        enableBroadcastForStudent,
        getParticipantName,
        getParticipantTreatmentType,
        makeFirstContact,
        nominateStudent,
        promoteStudent,
        // fillDrill,
        sendMessage,
        setAllowShortcuts,
        setConversationMode,
        setHolodeckLessonId,
        setHolodeckModuleId,
        setSlideIndex,
        startAudienceTurn: AudienceTurn.startAudienceTurn,
        toggleAllSpeakMode,
        toggleIsMuted,
        allParticipants,
        allowShortcuts,
        allSpeakMode,
        broadcastingStudentIds,
        candidateCount: candidateParticipants.length,
        candidateParticipants,
        conversationMode,
        currentIsAudience: AudienceTurn.currentIsAudience,
        currentSpeakerParticipant,
        eligibleCandidateCount:
          AudienceTurn.eligibleCandidateParticipants?.length,
        isConnectedToDaily,
        isMuted,
        loudestParticipant,
        mutedSpeakerStudentIds,
        observerCount:
          observerStudentIds.length + (candidateParticipants.length - AudienceTurn.eligibleCandidateParticipants.length),
        presentSpeakerParticipants,
        roomWindowEnded,
        roomWindowStarted,
        slideIndex,
        speakerCount: presentSpeakerParticipants?.length,
        userWasEjected,
        totalBeepboops,
        setTotalBeepboops,
      }}
    >
      {children}
    </CallContext.Provider>
  );

};

export const useHolodeck = () => useContext(CallContext);
