// this is not operational. 
// TODO: determine if it's worth adding this
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import activebuildconfig from "../../../../configs/activebuildconfig.json";
const { SPEAK_ROOT_URL } = activebuildconfig;

/**
 * ViewStory Component - Displays details of a selected story
 * @param {Object} story - Story object containing all story details
 * @returns {JSX.Element} Story details view
 */
const ViewStory = ({ story }) => {
  // React-router hook to use location object
  const search = useLocation().search;

  useEffect(() => {
    // If there is no storyId in the query string, but the story Props exists, add the storyId to the query string
    const storyId = new URLSearchParams(search).get("storyId");
    if (story.id && !storyId) {
      history.pushState(null, null, `/manage/stories?storyId=${story.id}`);
    }
  });

  return story.id === undefined ? (
    <div>No Story Selected</div>
  ) : (
    <>
      <h1 className="moduleDataColumn1">Unique Id:</h1>
      <p className="moduleDataColumn2">{story.id || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story World:</h1>
      <p className="moduleDataColumn2">{story.storyWorld || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story Chapter:</h1>
      <p className="moduleDataColumn2">{story.storyChapter || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story Number:</h1>
      <p className="moduleDataColumn2">{story.storyNumber || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story Name:</h1>
      <p className="moduleDataColumn2">{story.storyName || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Description:</h1>
      <p className="moduleDataColumn2">{story.description || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story Create Date:</h1>
      <p className="moduleDataColumn2">
        {story.storyCreateDate?.toDate().toLocaleString() || ""}
      </p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Hide from Story Tree:</h1>
      <p className="moduleDataColumn2">
        {(story.hideFromStoryTree || false).toString()}
      </p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Quiz URL:</h1>
      <p className="moduleDataColumn2">{story.quizUrl || ""}</p>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Related Drill Topic:</h1>
      <div className="moduleDataColumn2">
        <p>Name: {story.relatedDrillTopic1?.name || ""}</p>
        <p>L1 URL: {story.relatedDrillTopic1?.urlL1 || ""}</p>
        <p>L2 URL: {story.relatedDrillTopic1?.urlL2 || ""}</p>
        <p>L3 URL: {story.relatedDrillTopic1?.urlL3 || ""}</p>
      </div>
      <span className="cell"></span>

      <h1 className="moduleDataColumn1">Story Text:</h1>
      <p className="moduleDataColumn2" style={{ whiteSpace: "pre-wrap" }}>
        {story.storyText || ""}
      </p>
      <span className="cell"></span>

      <p className="moduleDataColumn1 centerLabel">Study Page:</p>
      <span className="linkToModule">
        {story ? (
          <p
            className="modulePageLink"
            onClick={() =>
              (window.location.href = `${SPEAK_ROOT_URL}/catalog/stories/${story.id}`)
            }
          >
            Story Page (Student)
          </p>
        ) : null}
      </span>
    </>
  );
};

export default ViewStory;
