import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Find icons to import at https://fontawesome.com/v5.15/icons
import { 
  faComment, 
  faCommentSlash, 
  faLevelUpAlt,
  faVolumeMute, 
} from "@fortawesome/free-solid-svg-icons";

import { useHolodeck } from "../Holodeck";

// variable that the recomendation on the promote / upgrade button
const PROMOTED_MAX = 4;
const LESSONS_MAX = 4;


/**
 * Render one section of the present participants list (students + instructor)
 * 
 * @param { Map } allStudentInfo - of all students keyed by user id (student id) container studentCreatedAtUTC and student_type
 * @param { String } treatmentType - of all participants in this section
 * @param { Array } participants - Array of objects with display name and audio
 * (Daily mute concept) for each
 * @returns markup for one section of student list
 */
const PresentParticipantListSection = ({
  allStudentInfo,
  treatmentType,
  participants,
}) => {
  // Extract needed Daily functions and values from context
  const {
    allSpeakMode,
    broadcastingStudentIds,
    disableBroadcastForStudent,
    enableBroadcastForStudent,
    promoteStudent,
  } = useHolodeck();

  // Helper function toggles broadcasting state of a given student
  function toggleBroadcast( studentId ) {
    if ( broadcastingStudentIds?.includes(studentId) ) {
      disableBroadcastForStudent(studentId);
    }
    else {
      enableBroadcastForStudent(studentId);
    }
  }

  // Map treatmentType enum value to a more human-friendly formatted title
  const sectionTitle =
    treatmentType === "SPEAKER"
      ? "Pro Spots 👑"
      : treatmentType === "OBSERVER"
        ? "Observer(s)"
        : treatmentType === "INSTRUCTOR"
          ? "Instructor"
          : "Audience";
  
  // Only return markup if we have participants in the list
  return participants.length
    ? (
      <section className={`presentParticipantList__section ${ treatmentType.toLowerCase() }`} >
        <h2>{ sectionTitle }</h2>
        <ul className="presentParticipantList__sectionList">
          {// Render one entry in the list for each participant
            participants.map(
              ( participant, index ) => {
                const studentInfo = allStudentInfo[participant.userId];
                return (
                  <li
                    key={`${ treatmentType }-${ index }`}
                    className={`presentParticipantList__participant ${
                      participant.audio ? "audible" : "muted"
                    } ${
                      participant.isLoudest ? "loudest" : ""
                    }`}
                  >
                    {// If student is not audible, put mute icon by name
                      !participant.audio
                    && (
                      <FontAwesomeIcon
                        icon={ faVolumeMute }
                        className="presentParticipantList__muteIcon"
                      />
                    )
                    }
                    {
                    // Only render broadcast toggle button if not an instructor + in allSpeak mode
                      treatmentType !== "INSTRUCTOR" && allSpeakMode &&
                    <div 
                      className="presentParticipantList__debroadcastButton willDebroadcast"
                      onClick={ () => toggleBroadcast(participant.userId) } 
                    >
                      { 
                        broadcastingStudentIds?.includes(participant.userId) ? 
                          <FontAwesomeIcon
                            icon={faComment}
                            className={"participantList__broadcastIcon"}
                          /> :
                          <FontAwesomeIcon
                            icon={faCommentSlash}
                            className={"participantList__broadcastIcon negativeState"}
                          />
                      }
                    </div>
                    }&nbsp;
                    { participant.name }&nbsp;
                    {
                      // Only render promote button if a student and not already a Speaker 
                      !["INSTRUCTOR", "SPEAKER", "OBSERVER"].includes(treatmentType) && studentInfo && (
                        <>
                          {
                            // Render promote button if student level is >= lesson level and drills_promoted is under 4
                            <div
                              onMouseDown={(e) => {
                                e.currentTarget.promotionTimeout = setTimeout(() => {
                                  promoteStudent(participant.userId);
                                }, 200); // .2 second press required to upgarde student (prevent accidental promotion)
                              }}
                              onMouseUp={(e) => {
                                clearTimeout(e.currentTarget.promotionTimeout);
                              }}
                              onMouseLeave={(e) => {
                                clearTimeout(e.currentTarget.promotionTimeout);
                              }}
                              onTouchStart={(e) => {
                                e.currentTarget.promotionTimeout = setTimeout(() => {
                                  promoteStudent(participant.userId);
                                }, 200); // 500 ms = 0.5 second
                              }}
                              onTouchEnd={(e) => {
                                clearTimeout(e.currentTarget.promotionTimeout);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faLevelUpAlt}
                                className={
                                  studentInfo.totalPromoted < PROMOTED_MAX
                                    ? "presentParticipantList__promoteIconSuggested"
                                    : "presentParticipantList__promoteIconNotSuggested"
                                }
                              />
                            </div>
                          }
                        </>
                      )
                    }
                    {
                      treatmentType !== "INSTRUCTOR" && studentInfo && (
                        <>
                          <span
                            className="emoji-crown"
                          >
                            { 
                              studentInfo.studentType === "TRIAL" || 
                              studentInfo.studentType === "ACTIVE" 
                                ? "" 
                                : "👑" 
                            }
                          </span>
                          
                          { studentInfo.totalAttended < LESSONS_MAX && (
                            <span 
                              title="This student is new, make sure they feel welcomed!"
                              className="emoji-new"
                            >
                              🆕
                            </span>
                          )}
                          &nbsp;{ `L${studentInfo.studentLevel} ` }
                          { `P${studentInfo.totalPromoted ?? 0} ` }
                          { `D${studentInfo.totalAttended ?? 0}` }
                        </>
                      )
                    }
                  </li>
                );
              },
            )
          }
        </ul>
      </section>
    )
    : null;
};

export default PresentParticipantListSection;
