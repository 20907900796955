import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { getOneStory } from "../../../services/datastore";
import { getCurrentUser } from "../../../services/user";

// Comment out StorySearch import
// import StorySearch from "../../../components/StorySearch";
import ViewStory from "./ViewStories";
import AddStory from "./AddStory";
import EditStory from "./EditStory";

import "./storyDetails.scss";

/**
 * COMPONENT StoryDetails
 *
 * Allows for searching, display, and editing of story information
 *
 */
const StoryDetails = ({ location, history }) => {
  /*
   * CONTEXT
   */
  const userInfo = getCurrentUser();

  // Check user permission to edit and add modules
  const userCanEditAndAddModules = userInfo?.isAdmin || userInfo?.canManageModules;

  /*
   * STATE
   */

  // Instantiate loading state to false - will trigger as true if we need to fetch a story
  const [loading, setLoading] = useState(false);

  // Store info on story
  const [story, setStory] = useState({});

  // Toggle between View, Add, and Edit modes
  const [selectedViewOption, setSelectedViewOption] = useState("View");

  /*
   * USE EFFECT
   */

  // On component load, check for story-indicating queryString
  useEffect(() => {
    // Try to grab the storyId value from the query string
    const storyId = getStoryIdFromQueryString();

    // If we have a storyId query string, load the appropriate story
    if (storyId) {
      // Async function fired and forgotten
      asyncGetStoryById(storyId);
    }
  }, []);

  // Story can be set by story search component. When it is, update query string
  // This is kind of backwards (child component setting state here, and this component adjusting queryString to match), but this works with minimal changes to StorySearch (which is being developed against in another feature branch)
  useEffect(() => {
    // Get id of story in state
    const stateStoryId = story.id;

    // Only proceed if we have a story with an id in state
    if (stateStoryId) {
      // Get id indicated by query string
      const queryStringStoryId = getStoryIdFromQueryString();

      // If the two don't match, update the query string to reflect state
      if (stateStoryId !== queryStringStoryId) {
        const query = new URLSearchParams(location.search);
        query.set("storyId", stateStoryId);
        history.push({
          pathname: location.pathname,
          search: query.toString(),
        });
      }
    }
  }, [ story ]);

  /*
   * HELPERS
   */

  // Helper to extract story id from query string
  const getStoryIdFromQueryString = () => {
    // Get query string from location
    const query = new URLSearchParams(location.search);

    // Try to grab the storyId value from the query string
    const storyId = query.get("storyId");

    // Return storyId if we get it, or false if it doesn't exist
    return storyId || false;
  };

  // Callback to select a story by its ID, query the database, and display its details
  const asyncGetStoryById = async (storyId) => {
    // First render the loading screen & clear story info
    setLoading(true);
    setStory({});

    // Then get story information
    const fetchedStory = await getOneStory(storyId);

    // Set story info with returned object, or empty one if no return
    setStory(fetchedStory || {});

    // Remove loading flag
    setLoading(false);
  };

  return (
    <>
      <div className="storySearchBar">
        {/* StorySearch component temporarily disabled
        {selectedViewOption === "View" && (story.id || !getStoryIdFromQueryString())
          ? (
            <StorySearch
              storyWasSelected={asyncGetStoryById}
              story={story}
              clearStory={() => {
                history.push({ search: ""});
                setStory({});
              }}
            />
          )
          : null}
        */}
      </div>
      {loading
        ? <div>Loading...</div>
        : (
          <div className={selectedViewOption === "View" ? "storyDetailsView" : "storyDetailsAddEdit"}>
            <div>
              {userCanEditAndAddModules ?
                <div className="storyDetailsViewOptions">
                  <p
                    className={selectedViewOption === "View"
                      ? "storyDetailsViewOption selected"
                      : "storyDetailsViewOption"}
                    onClick={() => setSelectedViewOption("View")}
                  >
                    View
                  </p>
                  <p
                    className={selectedViewOption === "Add"
                      ? "storyDetailsViewOption selected"
                      : "storyDetailsViewOption"}
                    onClick={() => {
                      setSelectedViewOption("Add");
                      history.push({
                        search: "",
                      });
                    }}
                  >
                    Add
                  </p>
                  {story.id ? <p
                    className={selectedViewOption === "Edit"
                      ? "storyDetailsViewOption selected"
                      : "storyDetailsViewOption"}
                    onClick={() => setSelectedViewOption("Edit")}
                  >
                    Edit
                  </p> : null}
                </div>
                : null
              }
              <div className="storyData">
                {selectedViewOption === "View"
                  ? <ViewStory story={story} />
                  : selectedViewOption === "Add" && userCanEditAndAddModules
                    ? <AddStory />
                    : selectedViewOption === "Edit" && userCanEditAndAddModules
                      ? <EditStory story={story} />
                      : null
                }
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default withRouter(StoryDetails);
