import React, { useState } from "react";
import { Link } from "react-router-dom";

// Get current environment
import activebuildconfig from "../../../../configs/activebuildconfig.json";
const { APP_POINTER } = activebuildconfig;

// User info from context
import { getCurrentUser } from "../../../services/user";

// Endpoint to retry failed room creation
import { retryFailedRoomCreation } from "../../../services/datastore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./manageMenu.scss";

/**
 * Render manage menu, sensitive to current environment and user permissions
 * 
 * @returns { JSX } Markup for "Manage" menu in header nav
 */
const ManageMenu = () => {
  /*
   * CONTEXT
   */
  
  const userInfo = getCurrentUser();

  /*
   * STATE
   */

  // State to manage menu open/close
  const [open, setOpen] = useState(false);

  /*
   * HELPERS
   */

  // Set open flag to opposite of its current value
  const toggleMenu = () => setOpen(!open);

  // To avoid repetition when styling links in sub-menu
  const ManageLink = ({ to, children }) => (
    <Link
      className="nav-link manage-menu__link"
      onClick={toggleMenu}
      to={to}
    >
      {children}
    </Link>
  );

  // Make Booking link is visible to admins in non-production environments
  const MakeBookingLink = () => (
    APP_POINTER !== "PROD" && userInfo.isAdmin
      ? (
        <li>
          <ManageLink to="/manage/makeBooking">
            Make Booking
          </ManageLink>
        </li>
      )
      : null
  );

  // Modules link is visible to all teachers
  const ModulesLink = () => (
    <li>
      <ManageLink to="/manage/modules">
        Modules
      </ManageLink>
    </li>
  );

  // Stories link is visible to all teachers
  const StoriesLink = () => (
    <li>
      <ManageLink to="/manage/stories">
        Stories
      </ManageLink>
    </li>
  );  
  
  // Manage Students link is visible to admins / teachers who can manage students
  const ManageStudentsLink = () => (
    userInfo.isAdmin || userInfo.canManageStudents
      ? (<li>
        <ManageLink to="/manage/students">
          Students  
        </ManageLink> 
      </li>)
      : null
  );

  // New Drill link is visible to admins in non-production environments
  const NewDrillLink = () => (
    APP_POINTER !== "PROD" && userInfo.isAdmin
      ? (
        <li>
          <ManageLink to="/manage/newdrill">
            New Drill
          </ManageLink>
        </li>
      )
      : null
  );

  // Manage wordBundles link is visible to admins / teachers who can manage wordBundles
  const ManageWordBundlesLink = () => (
    userInfo.isAdmin || userInfo.canManageWordBundles
      ? (<li>
        <ManageLink to="/manage/wordBundles">
          Word Bundles
        </ManageLink>
      </li>)
      : null
  );

  // ClassFeedback (teacher reviews) is visible to admins and teachers who can view reviews
  const TeacherReviewsLink = () => (
    userInfo.isAdmin || userInfo.canSeeAllTeacherReviews
      ? (
        <li>
          <ManageLink to="/manage/teacherReviews">
            Teacher Reviews
          </ManageLink>
        </li>
      )
      : null
  );

  // Add ability to retry failed room creation for admins
  const RetryFailedRoomCreationButton = () => (
    userInfo.isAdmin
      ? (
        <li onClick={() => {
          retryFailedRoomCreation();
        }}>
          Retry Rooms!
        </li>
      )
      : null
  );
  
  // All markup for Manage menu!
  return (
    <span className="nav-link manage-menu">
      <span className="manage-menu__label" onClick={toggleMenu}>
      Manage
        <FontAwesomeIcon className="manage-menu__icon" icon={faChevronDown} />
      </span>
      <ul className={`manage-menu__items${open ? " open" : ""}`}>
        { NewDrillLink() }
        { MakeBookingLink() }
        { ModulesLink() }
        { StoriesLink() }
        { ManageStudentsLink() }
        { ManageWordBundlesLink() }
        { TeacherReviewsLink() }
        { RetryFailedRoomCreationButton() }
      </ul>
    </span>
  );
};

export default ManageMenu;
